<template>
	<metainfo>
		<template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
	</metainfo>
	<header-app
		:showLogin="showLogin"
		:show="showLogin"
		:menu="menu"
		:categories="categories"
		@showLoginPageState="changeShowLoginState"
		@updateCategories="fetchMenu"
	></header-app>
	<div class="content">
		<router-view
			@show-login-modal="showLoginModal"
			:categories="categories"
			:seo="seo"
		></router-view>
	</div>
	<footer-app
		@showLoginPageState="changeShowLoginState"
		:showLogin="showLogin"
		:menu="menu"
		:categories="categories"
		@updateCategories="fetchMenu"
	></footer-app>
	<!--<cookies-app
			v-show="showCookies"
			@close-cookies="showCookies = false"
	></cookies-app>-->
</template>

<script>
import HeaderApp from './components/Header';
import FooterApp from './components/Footer';
// import CookiesApp from './components/Cookies'
import CategoryMixin from '@/mixins/category.js';
import { useOrganizationStore } from '@/store/organization.js';
import { useUserStore } from '@/store/user.js';
import { mapState, mapActions } from 'pinia';
import { api } from '@/api';

export default {
	name: 'App',
	mixins: [CategoryMixin],
	components: {
		HeaderApp,
		FooterApp,
		// CookiesApp,
	},
	data() {
		return {
			scrollTop: 0,
			oldScrollTop: 0,
			showLogin: false,
			organizations: [],
			seo: [],
			showCookies: true,
		};
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeMount() {
		this.fetchSeo();
		this.fetchOrganizations();
	},
	computed: {
		...mapState(useUserStore, ['getCookies']),
	},
	mounted() {
		console.log('APP loaded get fetch menu');
		this.fetchMenu();
		// connect websocket

		// useWSStore();
		let header = document.getElementById('header'),
			headerHeight = document.getElementById('header').offsetHeight;

		this.scrollTop = window.pageYOffset;

		if (this.scrollTop > headerHeight && header) {
			header.classList.add('fixed');
		} else if (header) {
			header.classList.remove('fixed');
		}
		if (this.getCookies == true) {
			this.showCookies = false;
		}
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		...mapActions(useOrganizationStore, ['setOrganizations']),
		changeShowLoginState(val) {
			this.showLogin = val;
		},
		showLoginModal() {
			this.changeShowLoginState(true);
		},
		handleScroll() {
			let header = document.getElementById('header'),
				headerHeight = document.getElementById('header').offsetHeight;

			this.scrollTop = window.pageYOffset;

			if (
				this.scrollTop > headerHeight &&
				this.scrollTop > this.oldScrollTop &&
				header
			) {
				header.classList.add('fixed');
			} else if (header) {
				header.classList.remove('fixed');
			}
			this.oldScrollTop = window.pageYOffset;
		},
		async fetchOrganizations() {
			const response = await api.getOrganizations();

			// const filtered = response.data.filter(organization => {
			// 	return organization.delivery_types.filter(type => type.type === 2);
			// });
			this.setOrganizations(response.data);
		},
		async fetchSeo() {
			const response = await api.getSeoData();
			this.seo = response.data;
		}
	},
};
</script>
