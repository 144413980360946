import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            deliveryAddress: null,
            deliveryName: null,
            token: null,
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            birthday: null,
            distance: null,
            deliveryType: 'Самовывоз',
            delivery_point: {
                address_line: null,
                city: null,
                street: null,
                building: null,
                flat: null,
                doorphone: null,
                entrance: null,
                longitude: null,
                latitude: null,
            },
            delivery: {
                cost: null,
                freeCost: null,
                minCost: null,
                minutes: null,
            },
            timeDelivery: 'Поскорее',
            timePickup: 'Поскорее',
            cookies: false,
        }
    },
    getters: {
        getDeliveryAddress: (state) => state.deliveryAddress,
        getToken: (state) => state.token,
        isAuthenticated: (state) => state.token !== null,
        getUserPhone: (state) => state.phone,
        getDeliveryPoint: (state) => state.delivery_point,
        getUser: (state) => ({
            firstName: state.firstName,
            lastName: state.lastName,
            phone: state.phone.replace(/[^+.\d]/g, ''),
            email: state.email,
            birthday: state.birthday,
            delivery_point: state.delivery_point,
        }),
        getDeliveryType: (state) => state.deliveryType,
        getDelivery: (state) => state.delivery,
        getTimeDelivery: (state) => state.timeDelivery,
        getTimePickup: (state) => state.timePickup,
        getCookies: (state) => state.cookies,
    },
    actions: {
        getDeliveryPrice(amount) {
            if (this.getDelivery.freeCost > amount) {
                return this.getDelivery.cost;
            } else {
                return 0;
            }
        },
        setDeliveryAddress(id) {
            this.deliveryAddress = parseInt(id);
        },
        setDeliveryPoint(data) {
            this.delivery_point = data;
        },
        setDeliveryName(name) {
            this.deliveryName = name;
        },
        authorize(token) {
            this.token = token;
        },
        setUserPhone(phone) {
            this.phone = phone;
        },
        setUserEmail(email) {
            this.email = email;
        },
        setUserName(firstName) {
            this.firstName = firstName;
        },
        setUserBirthday(birthday) {
            this.birthday = birthday;
        },
        setDistance(distance) {
            this.distance = distance;
        },
        setDeliveryType(type) {
            this.deliveryType = type;
        },
        setDelivery(delivery) {
            this.delivery.cost = delivery.cost;
            this.delivery.freeCost = delivery.freeCost;
            this.delivery.minCost = delivery.minCost;
            this.delivery.minutes = delivery.minutes;
        },
        setTimeDelivery(time) {
            this.timeDelivery = time;
        },
        setTimePickup(time) {
            this.timePickup = time;
        },
        setCookies(value) {
            this.cookies = value;
        },
    },
    persist: true,
});
