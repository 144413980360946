<template>
    <div class="modal-overlay" @click="toggleBodyClass(false), $emit('close-modal')">
        <div style="margin: auto;max-width: 100%;">
            <div class="modal code-modal" @click.stop>
                <div class="modal__close d-flex block-center transition" @click="toggleBodyClass(false), $emit('close-modal')">
                    <span class="icon-close transition"></span>
                </div>
                <div class="modal__inner d-flex flex-wrap">
                    <h2 class="modal__title">Введите код</h2>
                    <span class="modal__text">
                        Мы отправили вам сообщение с кодом для ввода в поле ниже на номер <br><b>{{ formatedUserPhone }}</b>
                        <span class="modal__text-link"
                              @click="$emit('close-modal'), $emit('show-login-modal')"
                        >
                            Изменить номер
                        </span>
                    </span>

                    <div class="code-modal__numbers d-flex">
                        <div class="code-modal__number">
                            <input
                                type="number"
                                v-model="code[0]"
                                class="code-modal__number-input transition"
                                placeholder="0"
                                pattern="[0-9]"
                                maxlength="1"
                                ref="code_1"
                                @input="checkNumberCode(1)">
                        </div>
                        <div class="code-modal__number">
                            <input
                                type="number"
                                v-model="code[1]"
                                class="code-modal__number-input transition"
                                placeholder="0"
                                pattern="[0-9]"
                                maxlength="1"
                                ref="code_2"
                                @input="checkNumberCode(2)">
                        </div>
                        <div class="code-modal__number">
                            <input
                                type="number"
                                v-model="code[2]"
                               class="code-modal__number-input transition"
                               placeholder="0"
                                pattern="[0-9]"
                                maxlength="1"
                                ref="code_3"
                                @input="checkNumberCode(3)">
                        </div>
                        <div class="code-modal__number">
                            <input
                                type="number"
                                v-model="code[3]"
                                class="code-modal__number-input transition"
                                placeholder="0"
                                pattern="[0-9]"
                                maxlength="1"
                                ref="code_4"
                                @input="checkNumberCode(4)">
                        </div>
                    </div>

                    <span class="code-modal__error" v-if="codeError">Введен неверный код</span>

                    <div class="modal__buttons d-flex flex-wrap">
                        <button
                                v-if="canSendCode"
                                @click="sendCode"
                                class="modal__buttons-btn modal__buttons-btn--grey d-flex block-center transition"
                        >
                            Отправить код
                        </button>
                        <button
                                @click="sendCode"
                                :disabled="timeInSeconds > 0"
                                class="modal__buttons-btn modal__buttons-btn--grey d-flex block-center transition"
                        >
                            Получить код {{ formattedTime }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { api } from '@/api';
    import { useUserStore } from '@/store/user.js';
    import { useOrderStore } from '@/store/order.js';
    import { mapState, mapActions } from 'pinia';

    export default {
        name: 'CodeModal',
        data() {
            return {
                code: [],
                timeInSeconds: 120,
                maxAttemp: 3,
                codeError: false,
                typeDelivery: 'Самовывоз',
            };
        },
        computed: {
            ...mapState(useUserStore, ['getUserPhone','getToken']),
            ...mapState(useOrderStore, ['getMenuId']),
            canSendCode() {
                return this.code.length === 4;
            },
            formattedTime() {
                const minutes = Math.floor(this.timeInSeconds / 60);
                const seconds = this.timeInSeconds % 60;
                if (this.timeInSeconds === 0) {
                    return '';
                }
                return ` через  ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            },
            formatedUserPhone() {
                return this.getUserPhone;
            }
        },
        beforeUnmount() {
            clearInterval(this.interval);
        },
        mounted() {
            this.startCountdown();
        },
        methods: {
            ...mapActions(useUserStore, ['authorize', 'setDeliveryPoint', 'setDeliveryType', 'setUserEmail', 'setUserName', 'setUserBirthday']),
            startCountdown() {
                this.interval = setInterval(() => {
                    if (this.timeInSeconds > 0) {
                        this.timeInSeconds--;
                    } else {
                        clearInterval(this.interval);
                        // Handle countdown completion if needed
                    }
                }, 1000);
            },
            checkNumberCode(index) {
                if (this.code[index - 1] >= 0 && this.code[index - 1] < 10) {
                    if (index >= 4) {
                        return;
                    }
                    this.$refs[`code_${(index + 1)}`].focus();
                } else {
                    this.code[index - 1] = '';
                }
            },
            toggleBodyClass(val) {
                document.body.classList.toggle('hidden', val);
            },
            unvalid() {
                const empty = this.code.find(i => {
                    return i.length === 0;
                });
                return empty;
            },
            async sendCode() {
                if (this.unvalid()) {
                    console.log('unvalid');
                    return;
                }
                if (this.getToken) {
                    try {
                        this.codeError = false;
                        await api.verifyUser(
                            {code: this.code.join("")}
                        );

                        this.$emit('close-modal');

                        const userData = await api.getUserData();

                        this.setDeliveryPoint(userData.data.delivery_point);
                        this.setUserEmail(userData.data.email);
                        this.setUserName(userData.data.first_name);
                        this.setUserBirthday(userData.data.birthday);

                        if (userData.data.delivery_point.address_line !== '') {
                            this.typeDelivery = 'Доставка по адресу';
                            // api.getMenuItem(this.getMenuId, this.typeDelivery);
                            this.setDeliveryType(this.typeDelivery);
                        } else {
                            this.setDeliveryType(this.typeDelivery);
                        }

                        this.$emit('show-delivery-modal');
                        this.emitter.emit('getDeliveryInfo');
                    } catch (e) {
                        this.codeError = true;
                    }
                } else {
                    try {
                        const response = await api.auth(
                            {code: this.code.join("")},
                            this.getUserPhone.replace(/[^+.\d]/g, '')
                        );
                        const { token } = response.data;
                        this.authorize(token);
                        this.codeError = false;

                        this.$emit('close-modal');

                        const userData = await api.getUserData();

                        this.setDeliveryPoint(userData.data.delivery_point);
                        this.setUserEmail(userData.data.email);
                        this.setUserName(userData.data.first_name);
                        this.setUserBirthday(userData.data.birthday);

                        if(userData.data.delivery_point.address_line !== '') {
                            this.typeDelivery = 'Доставка по адресу';
                            // api.getMenuItem(this.getMenuId, this.typeDelivery);
                            this.setDeliveryType(this.typeDelivery);
                        } else {
                            this.setDeliveryType(this.typeDelivery);
                        }

                        this.$emit('show-delivery-modal');
                        this.emitter.emit('getDeliveryInfo');
                    }catch (e) {
                        //code is wrong
                        this.codeError = true;
                    }
                }

            }
        },
    };
</script>
