import axios from 'axios';

import { useUserStore } from '@/store/user.js'

const baseUrl = process.env.VUE_APP_BASE_URL;
const authUrl = process.env.VUE_APP_AUTH_URL;
const seoUrl = process.env.VUE_APP_SEO_URL;
// const baseUrl = 'https://kstbcafe.ru/api/v1'; // Замініть це на фактичний URL вашого REST API
// const authUrl = 'https://kstbcafe.ru/api/auth/'; // Замініть це на фактичний URL вашого REST API
// const baseUrl = 'http://127.0.0.1:8020/api/v1'
// const authUrl = 'http://127.0.0.1:8020/api/auth/';

const api_ = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': "application/json",
    },
});

const api_seo = axios.create({
    baseURL: seoUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': "application/json",
    },
});

// Called in any request
api_.interceptors.request.use(function (config) {
    let token = useUserStore().getToken;
    if (token === null) {
        delete config.headers.Authorization
    } else {
        config.headers.Authorization = `Token ${token}`;
    }

    return config;
});

export const api = {
    getItems() {
        return api_.get(`items/`);
    },
    getMenu() {
        return api_.get(`menu`);
    },
    getOrganizations() {
        return api_.get(`organization`);
    },
    getMenuItem(id, type_delivery) {
        console.log('API getMenuItem id -', id);
        console.log('API getMenuItem type_delivery -', type_delivery);
        return api_.get(`menu/${id}?delivery_type=${type_delivery}`);
    },
    getProductsDetails(data) {
        return api_.get(`product?id=${data}`);
    },
    createOrder(data) {
        console.log('API createOrder -', data);
        return api_.post(`order`, data)
    },
    getOrderList() {
        return api_.get(`order`)
    },
    getOrder(id) {
        return api_.get(`order/${id}`)
    },
    createOrderPayment(data) {
        return api_.post(`order_payment`, data)
    },
    updatePaymentStatus(orderPaymenyId) {
        return api_.post(`order_payment/check?order_payment_id=${orderPaymenyId}`)
    },
    auth(data, authHeader = null) {
        let config = {};
        if (authHeader) {
            config = {
                headers: {
                    'X-Anonymous-Phone': authHeader
                }
            }
        }
        return axios.post(authUrl, data, config);
    },
    registration(data) {
        console.log('API registration data -', data);
        return api_.post('users', data);
    },
    updateUserData(data) {
        console.log('API updateUserData -', data);
        return api_.patch(`users/self/update`, data);
    },
    getUserData() {
        return api_.get('users/self');
    },
    verifyUser(code) {
        return api_.post('users/verify', code);
    },
    siteblocks(type) {
        return api_.get(`siteblock/${type}`);
    },
    getActionCards() {
        return api_.get(`action-card`);
    },
    getOrganization(id) {
        console.log('API getOrganization id -', id);
        return api_.get(`organization/${id}`);
    },
    getAllOrganization() {
        return api_.get(`organization`);
    },
    getSeoData() {
        return api_seo.get(`seo/pages`);
    }
    // Додайте інші функції для інших операцій з API
};
